import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(document.documentElement, 'hide');
  }
}
