import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDadataModule } from '@kolkov/ngx-dadata';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthInterceptor } from 'src/app/interceptors/auth.interceptor';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import * as fromPages from 'src/app/pages';

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    fromPages.HomeComponent,
    fromPages.DeliveryInfoComponent,
    fromPages.ClientCabinetComponent,
    fromPages.TrackingComponent,
    fromPages.NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxDadataModule,
    NgbModule,
    FormsModule,
    SharedModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }
    ]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
