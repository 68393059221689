<main>
  <h1>Отслеживание заявки</h1>
  <form class="form">
    <div class="row m-0">
      <div class="col-6 form-group pl-2 pr-2">
        <label for="orderId">ID заявки</label>
        <input class="form-control"
               type="text"
               id="orderId"
               name="orderId"
               [(ngModel)]="orderId"
        >
      </div>
      <div class="col-6 form-group pl-2 pr-2 mt-auto">
        <button class="btn btn-info" type="submit"
                [disabled]="!orderId || loading"
                (click)="getOrder()"
        >Проверить</button>
      </div>
    </div>
    <div class="alert alert-danger ml-2 mr-2 mt-1 mb-0" *ngIf="errorMessage">{{ errorMessage }}</div>
  </form>
  <div class="d-flex justify-content-center" *ngIf="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="row m-0" *ngIf="order">
    <div class="col-12 form-group pl-2 pr-2">
      <table class="table">
        <tbody>
          <tr>
            <th>Дата и время создания заявки</th>
            <td>{{ order?.createdAt | date: 'dd.MM.yyyy, H:mm:ss' :'+0300' }}</td>
          </tr>
          <tr>
            <th>Дата и время доставки в заявке</th>
            <td>
              {{ order?.startAt | date: 'dd.MM.yyyy':'+0300' }}
              с {{ order?.startAt | date: 'H:mm':'+0300' }} до
              {{ order?.finishAt | date: 'H:mm':'+0300' }}
            </td>
          </tr>
          <tr>
            <th>Фамилия клиента</th>
            <td>{{ order.client }}</td>
          </tr>
          <tr>
            <th>Статус заявки</th>
            <td>{{ order.status }}</td>
          </tr>
          <tr>
            <th>Подстатус заявки</th>
            <td>{{ order.status_reason_text }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</main>
