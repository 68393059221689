import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-prompt',
  templateUrl: './modal-prompt.component.html',
  styleUrls: ['./modal-prompt.component.scss']
})
export class ModalPromptComponent implements OnInit {
  result = '';

  @Input() public data = null;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  onKeydown(event: any): void {
    if (event.keyCode === 13 && this.result.trim()) {
      this.activeModal.close(this.result.trim());
    }
  }

}
