import { Injectable } from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class FormService {
  phonePattern = /^\d{10}$/;
  ruLetters = /^(?!\s)(?!-)(?!")(?!')[а-яё'"\s-]+$/i;
  fioPattern = /^(?!\s)(?!-)(?!")(?!')[a-zа-яё'"\s-]+$/i;


  getControls(formGroup: FormGroup): {[p: string]: AbstractControl} {
    return formGroup.controls;
  }

  showError(formGroup: FormGroup, fieldName: string): boolean {
    return this.getControls(formGroup)[fieldName].invalid &&
      (this.getControls(formGroup)[fieldName].touched || this.getControls(formGroup)[fieldName].dirty);
  }

  resetInputValidation(formGroup: FormGroup, fieldName: string): void {
    const inputControl = this.getControls(formGroup)[fieldName];
    if (
      inputControl.errors &&
      (inputControl.errors?.required || inputControl.errors?.pattern || inputControl.errors?.minlength)
    ) {
      inputControl.markAsUntouched();
      inputControl.markAsPristine();
    }
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getLocalDate(dateObject, time): string {
    const tomorrowDate = new Date();
    tomorrowDate.setDate(new Date().getDate() + 1);

    let dateLocal = new Date(
      dateObject.year ? dateObject.year : tomorrowDate.getFullYear(),
      dateObject.month ? dateObject.month - 1 : tomorrowDate.getMonth(),
      dateObject.day ? dateObject.day : tomorrowDate.getDate(),
      dateObject.hour || time,
      dateObject.minute || 0,
      0
    ).toLocaleString('sv-SE').slice(0, -3);
    dateLocal = ('0000' + dateLocal).substr(dateLocal.length - 12);
    return dateLocal.replace(' ', 'T');
  }
}
