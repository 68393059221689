import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DadataConfig, DadataType } from '@kolkov/ngx-dadata';
// import { DadataConfig, DadataSuggestion, DadataType, NgxDadataService } from '@kolkov/ngx-dadata';
// import { DadataResponse } from '@kolkov/ngx-dadata/lib/models/dadata-response';
// import { DadataAddress } from '@kolkov/ngx-dadata/lib/models/data';
import { finalize } from 'rxjs/operators';

import { FormService } from 'src/app/services/form.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-client-cabinet',
  templateUrl: './client-cabinet.component.html',
  styleUrls: ['./client-cabinet.component.scss']
})
export class ClientCabinetComponent extends FormService implements OnInit {
  loading: boolean;
  loadingNPS: boolean;
  loadingAction: boolean;
  // loadingAgreed: boolean;
  // loadingPostpone: boolean;
  partnerLogo: string;

  postponeMode: boolean;
  authCancelled: boolean;
  intervalList: Array<string>;
  // intervalAgreedList!: Array<string>;
  // intervalPostponeList!: Array<string>;

  logisticScheduleDates: any;
  availableTimeSlots: Array<any>;

  TodayDate: Date = new Date();
  TomorrowDate: Date = new Date();
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;

  agentPhoto!: string;
  token!: string;
  orderDetail: any;

  feedback: any;
  nps!: number;
  npsComment!: string;
  npsList = [
    {id: 0, title: '0  😢'},
    {id: 1, title: '1  😞'},
    {id: 2, title: '2  ☹️'},
    {id: 3, title: '3  🙁'},
    {id: 4, title: '4  😕'},
    {id: 5, title: '5  😐'},
    {id: 6, title: '6 🙂'},
    {id: 7, title: '7  😊'},
    {id: 8, title: '8 😁'},
    {id: 9, title: '9 😍'},
    {id: 10, title: '10  🤩'}
  ];
  agreedStatusList = ['NEW', 'REDIAL', 'REDIAL_BANK'];
  npsStatusList = ['PLANNED', 'COMPLETED', 'CANCELED', 'REFUSED'];
  endStatusList = ['COMPLETED', 'CANCELED', 'REFUSED'];

  errorMessage!: string;
  errorMessageNPS!: string;
  infoMessageNPS: string;
  errorActionMessage: string;

  // errorMessageAgreed!: string;
  // errorMessageDadataAgreed!: string;
  // errorMessagePostpone!: string;
  // errorMessageDadataPostpone!: string;

  config: DadataConfig = {
    apiKey: '71eeb232657d3d34975749abc78abad85ea70db2',
    type: DadataType.address
  };

  clForm = this.fb.group({
    deliveryAddress: ['', Validators.required],
    dadataAddress: [''],
    deliveryDate: [null, Validators.required],
    interval: ['', Validators.required],
    clientComment: [''],
  });

  // agreedForm = this.fb.group({
  //   deliveryAddress: ['', Validators.required],
  //   dadataAddress: [''],
  //   deliveryDate: [null, Validators.required],
  //   interval: ['', Validators.required],
  //   clientComment: [''],
  // });

  // postponeForm = this.fb.group({
  //   deliveryAddress: ['', Validators.required],
  //   dadataAddress: [''],
  //   deliveryDate: [null, Validators.required],
  //   interval: ['', Validators.required],
  //   clientComment: [''],
  // });

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private clientService: ClientService,
    // private ngxDadataService: NgxDadataService,
  ) {
    super();
    this.loading = true;
    this.TomorrowDate.setDate(this.TodayDate.getDate() + 1);
    this.minDate = this.getDate(this.TodayDate);
    const dateLater = new Date();
    dateLater.setMonth(new Date().getMonth() + 3);
    this.maxDate = this.getDate(dateLater);
    this.postponeMode = false;
    this.partnerLogo = '/assets/img/logo_cl.svg';
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token') as string;
    this.getCL();
    this.clientService.infoFeedback(this.token).subscribe((res: any) => {
      this.feedback = res.result;
      this.nps = this.feedback.nps;
      this.npsComment = this.feedback.comment;
    });
    this.clForm.get('dadataAddress')?.valueChanges.subscribe(() => {
      this.intervalList = [];
      this.clForm.patchValue({
        deliveryAddress: '',
        interval: '',
      });
    });
    this.clForm.get('deliveryDate')?.valueChanges.subscribe(() => {
      this.intervalList = [];
      this.clForm.patchValue({
        interval: '',
      });
      this.setIntervalList();
    });
    // this.agreedForm.get('dadataAddress')?.valueChanges.subscribe(() => {
    //   this.intervalAgreedList = [];
    //   this.errorMessageDadataAgreed = '';
    //   this.agreedForm.patchValue({
    //     deliveryAddress: '',
    //     interval: '',
    //   });
    // });
    // this.postponeForm.get('dadataAddress')?.valueChanges.subscribe(() => {
    //   this.intervalPostponeList = [];
    //   this.errorMessageDadataAgreed = '';
    //   this.postponeForm.patchValue({
    //     deliveryAddress: '',
    //     interval: '',
    //   });
    // });
  }

  getCL(): void {
    this.clientService.userCL(this.token).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: (res: any) => {
        this.orderDetail = res.result;
        // this.addressInfo = this.orderDetail.addressInfo ? this.orderDetail.addressInfo : this.addressInfo;
        this.agentPhoto = this.orderDetail.agentInfo?.photo ?
          'data:image/jpg;base64, ' + this.orderDetail.agentInfo.photo :
          '/assets/img/no_image.png';
        this.partnerLogo = res.result.partnerLogo ? `data:image/jpg;base64, ${res.result.partnerLogo}` : '/assets/img/logo_cl.svg';
        this.getLogisticSchedule();
        if (this.orderDetail?.status === 'NEW') {
          this.clForm.patchValue({
            dadataAddress: this.orderDetail.deliveryAddress,
            deliveryDate: this.getDate(this.orderDetail.deliveryTimeStart),
            interval: '' // todo: added interval from time start and finish
          });
          setTimeout(() => {
            this.clForm.patchValue({
              deliveryAddress: this.orderDetail.deliveryAddress
            });
          }, 1000);
          // this.agreedForm.patchValue({
          //   deliveryAddress: this.orderDetail.deliveryAddress,
          //   dadataAddress: this.orderDetail.deliveryAddress,
          //   deliveryDate: this.getDate(this.orderDetail.deliveryTimeStart),
          //   interval: '10:00-18:00'
          // });
          // this.ngxDadataService.getData(
          //   this.orderDetail.deliveryAddress,
          //   DadataType.address,
          //   {
          //     apiKey: this.config.apiKey
          //   }
          // ).subscribe((data: DadataResponse) => {
          //   const searchedAddress = data?.suggestions?.find(item => item.value === this.orderDetail.deliveryAddress);
          //   if (searchedAddress) {
          //     this.onAgreedAddressSelected(searchedAddress);
          //   } else {
          //     this.errorMessageDadataAgreed = 'Выберите корректный адрес встречи!';
          //   }
          // });
        }
      },
      error: (err: any) => {
        console.log('Get Order Detail Error: ', err);
        if (err.status === 403) {
          this.authCancelled = true;
        } else {
          this.errorMessage = err?.error?.error || 'Something went wrong!';
        }
      }
    });
  }

  getLogisticSchedule(address?: string): void {
    this.clientService.clientLogisticSchedule(this.token, address).subscribe({
      next: (res: any) => {
        if (res.result) {
          this.logisticScheduleDates = res.result;
          this.availableTimeSlots = this.logisticScheduleDates.availableTimeSlots || [];
          this.setIntervalList();
        }
      },
      error: err => {
        this.availableTimeSlots = [];
        this.intervalList = [];
      }
    });
  }

  setIntervalList(): void {
    const deliveryDate = this.clForm.get('deliveryDate')?.value;
    const currentDate = new Date(deliveryDate.year, deliveryDate.month - 1, deliveryDate.day)
      .toLocaleString('sv-SE')
      .slice(0, 10);
    this.intervalList = this.availableTimeSlots?.find(item => item.availableDate === currentDate)?.availableFixTimeInterval || [];
  }

  toAction(type: 'to-agreed' | 'to-postpone'): void {
    this.errorActionMessage = '';
    if (this.clForm.valid) {
      this.loadingAction = true;
      const data = this.clForm.value;

      const intervalSplit = data.interval.split('-').map((item: string) => Number(item.split(':')[0]));
      const startDate = this.getLocalDate(data.deliveryDate, intervalSplit[0]);
      const finishDate = this.getLocalDate(data.deliveryDate, intervalSplit[1]);
      const deliveryTimeStart = `${startDate}:00+03:00`;
      const deliveryTimeFinish = `${finishDate}:00+03:00`;

      const body = {
        deliveryAddress: data.deliveryAddress,
        deliveryTimeStart,
        deliveryTimeFinish,
        clientComment: data.clientComment,
      };
      const toActionRequest = type === 'to-agreed' ? this.clientService.orderAgreed(this.token, body) :
        this.clientService.orderPostpone(this.token, body);
      toActionRequest.pipe(
        finalize(() => this.loadingAction = false)
      ).subscribe({
        next: () => {
          this.getCL();
          if (type === 'to-postpone') {
            setTimeout(() => this.postponeMode = false, 1000);
          }
        },
        error: (err: any) => {
          this.errorActionMessage = err?.error?.error || 'Something went wrong!';
        }
      });
    }
  }

  // toAgreed(): void {
  //   this.errorMessageAgreed = '';
  //   if (this.agreedForm.valid) {
  //     this.loadingAgreed = true;
  //     const data = this.agreedForm.value;
  //
  //     const intervalSplit = data.interval.split('-').map((item: any) => Number(item.split(':')[0]));
  //     const startDate = this.getLocalDate(data.deliveryDate, intervalSplit[0]);
  //     const finishDate = this.getLocalDate(data.deliveryDate, intervalSplit[1]);
  //     const deliveryTimeStart = `${startDate}:00+03:00`;
  //     const deliveryTimeFinish = `${finishDate}:00+03:00`;
  //
  //     const agreedData = {
  //       deliveryAddress: data.deliveryAddress,
  //       deliveryTimeStart,
  //       deliveryTimeFinish,
  //       clientComment: data.clientComment,
  //       // addressInfo: this.addressInfo
  //     };
  //     this.clientService.orderAgreed(this.token, agreedData).pipe(
  //       finalize(() => this.loadingAgreed = false)
  //     ).subscribe({
  //       next: () => {
  //         this.getCL();
  //       },
  //       error: (err: any) => {
  //         this.errorMessageAgreed = err?.error?.error || 'Something went wrong!';
  //       }
  //     });
  //   }
  // }

  // toPostpone(): void {
  //   this.errorMessagePostpone = '';
  //   if (this.postponeForm.valid) {
  //     this.loadingPostpone = true;
  //     const data = this.postponeForm.value;
  //
  //     const intervalSplit = data.interval.split('-').map((item: string) => Number(item.split(':')[0]));
  //     const startDate = this.getLocalDate(data.deliveryDate, intervalSplit[0]);
  //     const finishDate = this.getLocalDate(data.deliveryDate, intervalSplit[1]);
  //     const deliveryTimeStart = `${startDate}:00+03:00`;
  //     const deliveryTimeFinish = `${finishDate}:00+03:00`;
  //
  //     const postponeData = {
  //       deliveryAddress: data.deliveryAddress,
  //       deliveryTimeStart,
  //       deliveryTimeFinish,
  //       clientComment: data.clientComment,
  //       // addressInfo: this.addressInfo
  //     };
  //     this.clientService.orderPostpone(this.token, postponeData).pipe(
  //       finalize(() => this.loadingPostpone = false)
  //     ).subscribe({
  //       next: () => {
  //         this.getCL();
  //         setTimeout(() => this.postponeMode = false, 1000);
  //       },
  //       error: (err: any) => {
  //         this.errorMessagePostpone = err?.error?.error || 'Something went wrong!';
  //       }
  //     });
  //   }
  // }

  onAddressSelected(): void {
    const addressData = this.clForm.get('dadataAddress')?.value;
    this.clForm.patchValue({
      deliveryAddress: addressData
    });
    this.getLogisticSchedule(addressData);
  }

  // onAgreedAddressSelected(event: DadataSuggestion): void {
  //   const dadataAddress = event.data as DadataAddress;
  //   if (dadataAddress.city_kladr_id || dadataAddress.settlement_kladr_id) {
  //     const kladrId = dadataAddress.settlement_kladr_id ? dadataAddress.settlement_kladr_id : dadataAddress.city_kladr_id;
  //     this.clientService.availableIntervals(this.token, kladrId).subscribe({
  //       next: (res: { result: string[]; }) => {
  //         const addressData = this.agreedForm.get('dadataAddress')?.value;
  //         this.agreedForm.patchValue({
  //           deliveryAddress: addressData
  //         });
  //         this.intervalAgreedList = res.result;
  //       },
  //       error: (err: any) => {
  //         console.log('Dadata Selected Address Get Interval Error: ', err);
  //         this.errorMessageDadataAgreed = 'Выберите корректный адрес встречи!';
  //       }
  //     });
  //   } else {
  //     this.errorMessageDadataAgreed = 'Выберите корректный адрес встречи!';
  //   }
  // }

  // onPostponeAddressSelected(event: DadataSuggestion): void {
  //   const dadataAddress = event.data as DadataAddress;
  //   if (dadataAddress.city_kladr_id || dadataAddress.settlement_kladr_id) {
  //     const kladrId = dadataAddress.settlement_kladr_id ? dadataAddress.settlement_kladr_id : dadataAddress.city_kladr_id;
  //     this.clientService.availableIntervals(this.token, kladrId).subscribe({
  //       next: (res: any) => {
  //         const addressData = this.postponeForm.get('dadataAddress')?.value;
  //         this.postponeForm.patchValue({
  //           deliveryAddress: addressData
  //         });
  //         this.intervalPostponeList = res.result;
  //       },
  //       error: (err: any) => {
  //         console.log('Dadata Selected Address Get Interval Error: ', err);
  //         this.errorMessageDadataPostpone = 'Выберите корректный адрес встречи!';
  //       }
  //     });
  //   } else {
  //     this.errorMessageDadataPostpone = 'Выберите корректный адрес встречи';
  //   }
  // }

  getDate(dateUTC: Date): NgbDateStruct {
    const dateLocal = new Date(dateUTC).toLocaleString('sv-SE', {timeZone: 'Europe/Moscow'});
    return {
      year: +dateLocal.slice(0, 4),
      month: +dateLocal.slice(5, 7),
      day: +dateLocal.slice(8, 10)
    };
  }

  submitNPS(): void {
    this.loadingNPS = true;
    this.feedbackMethod({
      nps: +this.nps,
      comment: this.npsComment
    }).pipe(
      finalize(() => this.loadingNPS = false)
    ).subscribe({
      next: (res: any) => {
        this.feedback = res.result;
        this.infoMessageNPS = 'Спасибо за оценку!';
        setTimeout(() => {
          this.infoMessageNPS = '';
        }, 3500);
      },
      error: (err: any) => {
        this.errorMessageNPS = err?.error?.error || 'Something went wrong!';
      }
    });
  }

  feedbackMethod(data: any): any {
    return this.feedback ?
      this.clientService.updateFeedback(this.token, data) :
      this.clientService.createFeedback(this.token, data);
  }

  isCustomDisabled = (date: NgbDate): boolean => {
    const currentDate = new Date(date.year, date.month - 1, date.day)
      .toLocaleString('sv-SE')
      .slice(0, 10);
    return !this.availableTimeSlots?.some(item => item.availableDate === currentDate);
  }

}
