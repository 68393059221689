import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DadataConfig, DadataSuggestion, DadataType } from '@kolkov/ngx-dadata';
import Inputmask from 'inputmask';
import { finalize } from 'rxjs/operators';
import { ClientService } from 'src/app/services';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-delivery-info',
  templateUrl: './delivery-info.component.html',
  styleUrls: ['./delivery-info.component.scss']
})
export class DeliveryInfoComponent implements OnInit {
  token: string;
  errorMessage: string;
  loading: boolean;
  deliveryInfo: any;
  productList: Array<any>;
  form = this.fb.group({
    address: ['', Validators.required],
    productID: ['', Validators.required],
    coords: ['', Validators.required],
  });

  dadataAddress: any;
  config: DadataConfig = {
    apiKey: '71eeb232657d3d34975749abc78abad85ea70db2',
    type: DadataType.address
  };

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    this.clientService.productList(this.token).subscribe(res => {
      this.productList = res.list;
    });
    this.setMask();
  }

  setMask(): void {
    setTimeout(() => {
      const maskedElement = document.getElementById('coords');
      if (maskedElement) {
        Inputmask('99.999999,99.999999', {
          placeholder: '',
          showMaskOnHover: false,
          showMaskOnFocus: false
        }).mask(maskedElement);
      }
    }, 200);
  }

  submit(): void {
    const data = this.form.value;
    this.errorMessage = this.getErrorMessage(data);
    if (!this.errorMessage) {
      this.loading = true;
      this.deliveryInfo = null;
      data.productID = +data.productID;
      data.coords = data.coords ? data.coords.split(',').map(Number) : null;

      this.clientService.deliveryInfo(this.token, data).pipe(
        finalize(() => this.loading = false)
      ).subscribe({
        next: res => {
          this.deliveryInfo = res;
          if (!this.deliveryInfo.availableTimeSlots?.length) {
            this.errorMessage = 'В данный населенный пункт нет доставки!';
          }
        },
        error: err => {
          this.errorMessage = 'В данный населенный пункт нет доставки!';
        }
      });
    }
  }

  getErrorMessage(data: any): string {
    if (!data.address && !data.coords) {
      return 'Введите адрес доставки или координаты!';
    }
    if (!data.address && data.coords.length !== 19) {
      return 'Введите корректные координаты!';
    }
    if (!data.productID) {
      return 'Выберите продукт!';
    }
    return '';
  }

  onAddressSelected(event: DadataSuggestion): void {
    this.form.patchValue({
      address: event.value
    });
  }

  changeCoords(): void {
    this.dadataAddress = '';
    this.form.patchValue({
      address: '',
    });
  }

  changeDadataAddress(): void {
    this.form.patchValue({
      address: '',
      coords: '',
    });
  }

}
