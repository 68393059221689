<div class="modal-header">
  <h4 class="modal-title">{{ data.title }}</h4>
</div>
<div class="modal-body">
  <form>
    <div class="form-group">
      <label for="result" class="col-form-label">{{ data.label }}:</label>
      <input type="text"
             class="form-control"
             id="result"
             name="result"
             [placeholder]="data.placeholder"
             [(ngModel)]="result"
             (keydown)="onKeydown($event)">
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">{{ data.cancelText || 'Отмена'}}</button>
  <button type="button"
          class="btn btn-primary"
          [disabled]="!result.trim() && !data.nonRequiredResult"
          (click)="activeModal.close(result.trim())"
  >{{ data.okText || 'Ошибка' }}</button>
</div>
