import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient
  ) { }

  userCL(token: string): Observable<any> {
    return this.http.get<any>(`/api/v1/cl/${token}`);
  }

  clientLogisticSchedule(token: string, address?: string): Observable<any> {
    const body: any = {};
    if (address) {
      body.address = address;
    }
    return this.http.post<any>(`/api/v1/cl/${token}/order/logistic-schedule/date`, body);
  }

  orderAgreed(token: string, params: Params): Observable<any> {
    return this.http.post<any>(`/api/v1/cl/${token}/order/agreed`, params);
  }

  orderPostpone(token: string, params: Params): Observable<any> {
    return this.http.post<any>(`/api/v1/cl/${token}/order/postpone`, params);
  }

  // availableIntervals(token: string, kladrId: string): Observable<any> {
  //   return this.http.post<any>(`/api/v1/cl/${token}/order/available-intervals`, {kladrId});
  // }

  createFeedback(token: string, params: Params): Observable<any> {
    return this.http.post<any>(`/api/v1/cl/${token}/order/feedback/create`, params);
  }

  updateFeedback(token: string, params: Params): Observable<any> {
    return this.http.post<any>(`/api/v1/cl/${token}/order/feedback/update`, params);
  }

  infoFeedback(token: string): Observable<any> {
    return this.http.post<any>(`/api/v1/cl/${token}/order/feedback/info`, {});
  }

  getOrder(id: string): Observable<any> {
    return this.http.get<any>(`/api/v1/order/open-info?id=${id}`);
  }

  productList(token: string): Observable<any> {
    return this.http.get<any>(`/api/v1/partner-open/${token}/product/list`);
  }

  deliveryInfo(token: string, params: Params): Observable<any> {
    return this.http.post<any>(`/api/v1/partner-open/${token}/geo-info`, params);
  }

}
