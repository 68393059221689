import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  orderId: string;
  loading: boolean;
  errorMessage: string;
  order: any;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit(): void { }

  getOrder(): void {
    if (this.orderId) {
      this.loading = true;
      this.errorMessage = '';
      this.clientService.getOrder(this.orderId).pipe(
        finalize(() => this.loading = false)
      ).subscribe(res => {
        this.order = res.result;
        console.log(this.order, 'order');
      }, err => {
        this.errorMessage = err.error.error;
      });
    }
  }

}
